export const tableHeaderLaberObject = {
    // 植烟环境-气象-降雨量
    '2': {
        sampleCode: '样品编码',

        particularYear: '年份',

        province: '省份',

        stateCity: '州市',

        distinctCounty: '区县',

        latitude: '纬度',

        longitude: '经度',

        annualRainfall: '年降雨量',

        filedRainfall: '大田期降雨量',

        january: '1月',

        february: '2月',

        march: '3月',

        april: '4月',

        may: '5月',

        june: '6月',

        july: '7月',

        august: '8月',

        september: '9月',

        october: '10月',

        november: '11月',

        december: '12月',

        comprehensiveEvaluation: '综合评价',
    },

    // 植烟环境-气象-气温
    '3': {
        sampleCode: '样品编码',

        particularYear: '年份',

        province: '省份',

        stateCity: '州市',

        distinctCounty: '区县',

        latitude: '纬度',

        longitude: '经度',

        annualAverageTemperature: '年平均温度',

        filedAverageTemperature: '大田期平均温度',

        january: '1月',

        february: '2月',

        march: '3月',

        april: '4月',

        may: '5月',

        june: '6月',

        july: '7月',

        august: '8月',

        september: '9月',

        october: '10月',

        november: '11月',

        december: '12月',

        activeTemperature: '年活动积温',

        noFrost: '无霜期',

        comprehensiveEvaluation: '综合评价',
    },

    // 植烟环境-气象-日照时数
    '4': {
        sampleCode: '样品编码',

        particularYear: '年份',

        province: '省份',

        stateCity: '州市',

        distinctCounty: '区县',

        latitude: '纬度',

        longitude: '经度',

        annualSunshineHours: '年日照时数',

        filedSunshineHours: '大田期日照时数',

        january: '1月',

        february: '2月',

        march: '3月',

        april: '4月',

        may: '5月',

        june: '6月',

        july: '7月',

        august: '8月',

        september: '9月',

        october: '10月',

        november: '11月',

        december: '12月',

        sunshinePercentage: '日照百分率',

        comprehensiveEvaluation: '综合评价',
    },

    // 植烟环境-土壤常规养分
    '5': {
        sampleCode: '样品编号',

        particularYear: '年份',

        province: '省份',

        stateCity: '州市',

        distinctCounty: '区县',

        townShip: '乡镇',

        latitude: '纬度',

        longitude: '经度',

        samplePoint: '样点',

        soilType: '土壤类型',

        phValue: 'pH值',

        organicMatter: '有机质',

        totalNitrogen: '全氮',

        totalPhosphorus: '全磷',

        totalPotassium: '全钾',

        alkaliHydrolyzedNitrogen: '碱解氮',

        availablePhosphorus: '速效磷',

        availablePotassium: '速效钾',

        chlorideIon: '氯离子',

        availableBoron: '有效硼',

        exchangeableMagnesium: '交换性镁',

        comprehensiveEvaluation: '综合评价',
    },

    // 烟叶质量-常规化学成分
    '21': {
        sampleCode: '样品编码',

        year: '年份',

        province: '省份',

        city: '州市',

        county: '区县',

        town: '乡镇',

        varieties: '品种',

        grade: '等级',

        totalSugar: '总糖',

        potassiumIon: '钾离子',

        reducingSugar: '还原糖',

        totalAlkaloids: '总植物碱',

        chlorideIon: '氯离子',

        totalNitrogen: '总氮',

        starch: '淀粉',

        petroleumEtherExtract: '石油醚提取物',

        pH: 'pH',

        potassiumChlorideRatio: '钾氯比',

        sugarAlkaliRatio: '糖碱比',
        
        nitrogenAlkaliRatio: '氮碱比',

        evaluate: '综合评价',

        longitude: '经度',

        latitude: '纬度',
    },
    // 烟叶质量-外观品质
    '22': {
        sampleCode: '样品编码',

        year: '年份',

        province: '省份',

        city: '州市',

        county: '区县',

        town: '乡镇',

        varieties: '品种',

        grade: '等级',

        color: '颜色',

        maturity: '成熟度',

        bladeStructure: '叶片结构',

        identity: '身份',

        oil: '油份',

        chroma: '色度',

        length: '长度',

        width: '宽度',

        mutilation: '残伤',

        evaluate: '综合评价',

        longitude: '经度',

        latitude: '纬度',
    },

    // 物理特性
    '23': {
        sampleCode: '样品编码',

        year: '年份',

        province: '省份',

        city: '州市',

        county: '区县',

        town: '乡镇',

        varieties: '品种',

        grade: '等级',

        thickness: '厚度',

        stemPercentage: '含梗率',

        pageDensity: '页面密度',

        tensionResistance: '抗张力',

        tensileStrength: '抗张强度',

        equilibriumMoistureContent: '平衡含水率',

        fillValue: '填充值',

        singleLeafWeight: '单叶重',

        evaluate: '综合评价',

        longitude: '经度',

        latitude: '纬度',
    },

    // 感官评吸
    '24': {
        sampleCode: '样品编码',

        year: '年份',

        province: '省份',

        city: '州市',

        county: '区县',

        town: '乡镇',

        varieties: '品种',

        grade: '等级',

        flavorType: '香型',

        aromaValue: '香气值',

        aromaQuantity: '香气量',

        miscellaneousGas: '杂气',

        concentration: '浓度',

        thrill: '刺激性',

        aftertaste: '余味',

        totalScore: '总分',

        grey: '灰色',

        flammability: '燃烧性',

        useValue: '使用价值',

        energy: '劲头',

        qualityDescribe: '感官品质描述',

        evaluate: '综合评价',

        longitude: '经度',

        latitude: '纬度',
    },

    // 色素多酚
    '25': {
        sampleCode: '样品编码',

        year: '年份',

        province: '省份',

        city: '州市',

        county: '区县',

        town: '乡镇',

        varieties: '品种',

        grade: '等级',

        carotenoids: '类胡萝卜素',

        carotene: 'β-胡萝卜素',

        chlorogenicAcid: '绿原酸',

        hyoscyamine: '莨菪亭',

        rutin: '芸香苷',

        evaluate: '综合评价',

        longitude: '经度',

        latitude: '纬度',
    }
}