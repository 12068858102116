//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BigNumber from "bignumber.js";
import {echartsIndex} from "../../../components/tobacco/echarts-index";

export default {
	name: "quality-year-echarts",
	computed: {
		list() {
			return this.$store.state['tobaccoAnalyse'].lists;
		},
		selectedRegionObject() {
			return this.$store.state['region'].selectedRegionObject;
		},
		colorList() {
			return this.$store.state['tobaccoAnalyse'].colorList;
		},
		type() {
			return this.$store.state['tobaccoAnalyse'].type;
		},
		params() {
			return this.$store.state['tobaccoAnalyse'].queryParams;
		},
		targetCheck() {
			return this.$store.state['tobaccoAnalyse'].targetCheck;
		},
	},
	watch: {
		list(value) {
			if (value) {
				this.getChart();
			}
		}
	},
	data() {
		return {
			headerTitle: '',
			indexList: [],
			indexCh: [],
			yearArr: [],
			barSource: [],
			barOption: {
				title: {
					text: '',
					left: 'center',
					textStyle: {//标题颜色
						color: "#fff",
					}
				},
				legend: {
					textStyle: {
						color: "#fff"
					}
				},
				toolbox: {
					show: false,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				grid: {
					top: '18%',
					right: '10%',
				},
				label: {
					show: true,
					color: '#fff',
					position: 'top',
					fontSize: "8px",
				},
				tooltip: {},
				dataset: {
					source: []
				},
				xAxis: {
					type: 'category',
					name: '年份',
					axisLine: {
						lineStyle: {
							color: "#fff",
						}
					},
					axisLabel: {
						rotate: 0
					}
				},
				yAxis: {
					type: "value",
					name: "指标平均值", //y坐标轴的名字
					axisLine: {
						lineStyle: {
							color: "#fff",
						}
					},
					axisTick: {show: true},
					splitLine: {show: false},
				},

				dataZoom: [
					{
						xAxisIndex: 0, //这里是从X轴的0刻度开始
						show: true, //是否显示滑动条，不影响使用
						type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
						start: 0, // 从头开始。
						end: 50, // 一次性展示3个。
						height: 20,  //X轴滚动条宽度
						textStyle: {
							color: '#fff'
						}
					},
					{
						type: 'inside',
						start: 94,
						end: 100,
					},
				],
				series: []
			}
		}
	},
	methods: {
		getChart() {
			if (!this.list || this.list.length === 0) {
				this.barSource.series = [];
				this.barOption.dataset.source = [];
				this.barOption.title.text = "暂无数据";
				return;
			}
			this.getArr();
			this.getValue();

			let titleText = this.selectedRegionObject.name
				+ this.targetCheck + '年份统计图';
			this.barOption.title.text = titleText;
			this.barOption.title.show = false;
			this.headerTitle = titleText;
			this.barOption.series = this.getBarSeries(this.indexCh);
			this.barOption.dataset.source = this.barSource;
			// this.$refs.bar.chart.on('click', 'series', () => {
			// 	this.$emit("openQualityYearEcharts", this.barOption);
			// })
		},

		handlerFullScreen() {
			this.$emit("openQualityYearEcharts", this.barOption);
		},

		/**
		 * 获取指标与行政区列表
		 * @param lists
		 * @returns {*[]}
		 */
		getArr() {
			this.yearArr = [];
			this.indexList = [];
			this.indexCh = [];
			if (this.type === '2') {
				echartsIndex.rain.forEach(item => {
					this.indexList.push(item.value);
					this.indexCh.push(item.name);
				})
			}
			if (this.type === '3') {
				echartsIndex.airTemperature.forEach(item => {
					this.indexList.push(item.value);
					this.indexCh.push(item.name);
				})
			}
			if (this.type === '4') {
				echartsIndex.sunshineHours.forEach(item => {
					this.indexList.push(item.value);
					this.indexCh.push(item.name);
				})
			}
			if (this.type === '5') {
				echartsIndex.soilConventionalNutrients.forEach(item => {
					this.indexList.push(item.value);
					this.indexCh.push(item.name);
				})
			}
			// 年份
			this.list.forEach(item => {
				if (item['particularYear'] && !this.yearArr.includes(item['particularYear'])) {
					this.yearArr.push(item['particularYear']);
				}
			})
			this.yearArr.sort();
		},

		/**
		 * 获取数据
		 * @param value
		 */
		getValue() {
			this.barSource = [];
			let source = [];
			let breed = ['产地'];
			this.indexCh.forEach(item => {
				breed.push(item);
			})
			source.push(breed);
			this.yearArr.forEach(a => {
				let temp = [a];
				this.indexList.forEach(b => {
					let count = new BigNumber("0");
					let sum = new BigNumber("0");
					this.list.forEach(c => {
						if (a && c) {
							if (a === c['particularYear']) {
								let value = new BigNumber(c[b]);
								if (value > 0) {
									sum = sum.plus(value);
									count = count.plus(new BigNumber("1"));
								}
							}
						}
					})

					temp.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
				})
				source.push(temp);
			})
			this.barSource = source;
		},
		/**
		 * 拼接柱状图的series
		 * @param names
		 * @returns {*[]}
		 */
		getBarSeries(names) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						type: 'bar',
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						}
					})
				}
			}
			return series;
		}
	},
}

