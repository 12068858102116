//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BigNumber from 'bignumber.js';
import { echartsIndex } from '@/components/tobacco/echarts-index';

export default {
    name: 'soil-type-echarts',
    computed: {
        list() {
            return this.$store.state['tobaccoAnalyse'].list;
        },
        selectedRegionObject() {
            return this.$store.state['region'].selectedRegionObject;
        },
        colorList() {
            return this.$store.state['tobaccoAnalyse'].colorList;
        },
        type() {
            return this.$store.state['tobaccoAnalyse'].type;
        },
        params() {
            return this.$store.state['tobaccoAnalyse'].queryParams;
        },
        targetCheck() {
            return this.$store.state['tobaccoAnalyse'].targetCheck;
        },
    },
    created() {
        if (this.type === '21') {
            this.pointerList = echartsIndex.conventionalChemical;
        }
        if (this.type === '22') {
            this.pointerList = echartsIndex.appearanceQuality;
        }
        if (this.type === '23') {
            this.pointerList = echartsIndex.physical;
        }
        if (this.type === '24') {
            this.pointerList = echartsIndex.sensory;
        }
        if (this.type === '25') {
            this.pointerList = echartsIndex.pigment;
        }
        this.pointerType = this.pointerList[0].value;
    },
    data() {
        return {
            headerTitle: '',
            pointerType: '',
            pointerList: [],
            varietiesArr: [],
            areaList: [],
            barSource: [],
            barOption: {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        //标题颜色
                        color: '#fff',
                    },
                },
                legend: {
                    pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff',
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 10,
                    },
                    type: 'scroll',
                },
                toolbox: {
                    show: false,
                    feature: {
                        //可下载为图
                        saveAsImage: { show: true },
                    },
                },
                grid: {
                    top: '18%',
                    right: '10%',
                },
                label: {
                    show: true,
                    color: '#fff',
                    position: 'top',
                },
                tooltip: {},
                dataset: {
                    source: [],
                },
                xAxis: {
                    type: 'category',
                    name: '产地',
                    axisLine: {
                        //这是y轴文字颜色
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    axisLabel: {
                        rotate: 0,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '指标平均值', //y坐标轴的名字
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    axisTick: { show: true },
                    splitLine: { show: false },
                },
                dataZoom: [
                    {
                        xAxisIndex: 0, //这里是从X轴的0刻度开始
                        show: true, //是否显示滑动条，不影响使用
                        type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                        start: 0, // 从头开始。
                        end: 50, // 一次性展示6个。
                        height: 20, //X轴滚动条宽度
                        textStyle: {
                            color: '#fff',
                        },
                    },
                    {
                        type: 'inside',
                        start: 94,
                        end: 100,
                    },
                ],
                series: [],
            },
        };
    },
    watch: {
        list(value) {
            if (value) {
                this.getChart();
            }
        },
    },
    methods: {
        /**
         * 得到图形
         */
        getChart() {
            // 处理数据
            if (this.list.length === 0) {
                this.barSource.series = [];
                this.barOption.dataset.source = [];
                this.barOption.title.text = '暂无数据';
                return;
            }
            // 获取土壤类型和年份
            this.getArr();
            // 获取value值
            this.getValue();

            let titleText =
                this.params.year + '年' + this.selectedRegionObject.name + '-' + this.targetCheck + '种类统计图';
            this.barOption.title.text = titleText;
            this.barOption.title.show = false;
            this.headerTitle = titleText;

            this.barOption.yAxis.name = this.pointerName;
            this.barOption.series = this.getBarSeries(this.areaList);
            this.barOption.dataset.source = this.barSource;
            // this.$refs.bar.chart.on('click', 'series', () => {
            // 	this.$emit("openSoilTypeEcharts", this.barOption);
            // })
        },

        handlerFullScreen() {
            this.$emit('openSoilTypeEcharts', this.barOption);
        },

        /**
         * 更改指标
         */
        changePointer() {
            this.getChart();
        },

        /**
         * 获取品种与行政区列表
         * @param lists
         * @returns {*[]}
         */
        getArr() {
            this.varietiesArr = [];
            this.areaList = [];
            // 获取土壤类型，以结果列表的为准
            this.list.forEach((item) => {
                if (item.varieties && !this.varietiesArr.includes(item.varieties)) {
                    this.varietiesArr.push(item.varieties);
                }
            });
            // 获取行政区
            if (this.selectedRegionObject.childrenList) {
                this.selectedRegionObject.childrenList.forEach((item) => {
                    this.areaList.push(item.name);
                });
            }
        },

        /**
         * 获取
         * @param value
         */
        getValue() {
            this.barSource = [];
            let source = [];
            let breed = ['品种'];
            this.areaList.forEach((item) => {
                breed.push(item);
            });
            source.push(breed);
            this.varietiesArr.forEach((a) => {
                let temp = [a];
                this.areaList.forEach((b) => {
                    let count = new BigNumber('0');
                    let sum = new BigNumber('0');
                    this.list.forEach((c) => {
                        if (a && b && c) {
                            if (a && c) {
                                let flagA = false;
                                let flagB = false;
                                let flagC = false;
                                let flagD = false;
                                if (c['province']) {
                                    flagA = c['province'].indexOf(b) != -1 || b.indexOf(c['province']) != -1;
                                }
                                if (c['city']) {
                                    flagB = c['city'].indexOf(b) != -1 || b.indexOf(c['city']) != -1;
                                }
                                if (c['county']) {
                                    flagC = c['county'].indexOf(b) != -1 || b.indexOf(c['county']) != -1;
                                }
                                if (c['town']) {
                                    flagD = c['town'].indexOf(b) != -1 || b.indexOf(c['town']) != -1;
                                }
                                if ((flagA || flagB || flagC || flagD) && c['varieties'] === a) {
                                    let value = new BigNumber(c[this.pointerType]);
                                    if (value > 0) {
                                        sum = sum.plus(value);
                                        count = count.plus(new BigNumber('1'));
                                    }
                                }
                            }
                        }
                    });

                    temp.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
                });
                source.push(temp);
            });
            this.barSource = source;
        },

        /**
         * 拼接柱状图的series
         * @param names
         * @returns {*[]}
         */
        getBarSeries(names) {
            let series = [];
            let size = this.colorList.length;
            if (names) {
                for (let i = 0; i < names.length; i++) {
                    series.push({
                        type: 'bar',
                        name: names[i],
                        itemStyle: {
                            normal: {
                                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
                                    {
                                        offset: 0,
                                        color: this.colorList[i % size][0],
                                    },
                                    {
                                        offset: 1,
                                        color: this.colorList[i % size][1],
                                    },
                                ]),
                            },
                        },
                    });
                }
            }
            return series;
        },
    },
};
