//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dataUtil } from '@/utils/data-util';

export default {
    name: 'control-area',
    inject: ['showLoading', 'hideLoading'],
    computed: {
        areaTree() {
            return this.$store.state['areaManagement'].areaTree;
        },
        typeList() {
            return this.$store.state['tobaccoQuality'].typeList;
        },
        isAll() {
            return this.$store.state['tobaccoAnalyse'].isAll;
        },
    },
    data() {
        return {
            // 行政区域
            casProps: {
                value: 'code',
                label: 'name',
                children: 'childrenList',
                checkStrictly: true,
            },
            areaList: [530000],
            // 大类
            typeOptions: [
                {
                    value: '1',
                    label: '植烟环境',
                },
                {
                    value: '2',
                    label: '烟叶质量',
                },
            ],
            typeValueValue: '1',
            // 字典
            dict: [],
            environmentType: 'analyse_environment',
            qualityType: 'analyse_quality',
            levelType: 'tobacco_level',
            // 一级目录
            oneLevel: [],
            oneLevelValue: '',
            oneLevelObject: {},
            // 二级目录
            towLevel: [],
            towLevelValue: '',
            towLevelObject: {},
            // 等级
            levelList: [],
            levelValue: '',
            levelObject: {},
            // 品种
            varieties: '',
            varietiesTemp: '',
            // 年份
            particularYearList: [],
            particularYear: 2022,
        };
    },
    async mounted() {
        // 请求行政区划树
        await this.$store.dispatch('areaManagement/getAreaTree');
        // 初始化时间
        this.particularYearList = dataUtil.getDateTimes(false);
        // 获取字典值
        let res = await this.$store.dispatch('dict/getDictEntity', {
            type: [this.environmentType, this.qualityType, this.levelType],
        });
        this.dict = res.data;
        // 初始化
        await this.initialization();
    },
    methods: {
        /**
         * 初始化一些数据
         */
        initialization() {
            // 初始化行政区
            // 默认为云南省
            this.$store.dispatch('region/setSelectedRegionObject', this.areaTree[0]);
            // 获取行政区名称列表
            let res = dataUtil.getAreaName(this.areaList, this.areaTree);
            if (res) {
                // 更改vue的行政区值
                this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                    key: 'areaCode',
                    value: res,
                });
            }
            // 更改年份选择值
            this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                key: 'year',
                value: this.particularYear,
            });
            // 初始化分类
            this.changeType();
        },

        /**
         * 搜索的行政区划发生变化
         */
        changeArea() {
            // 获取行政区名称列表
            let res = dataUtil.getAreaName(this.areaList, this.areaTree);
            if (res) {
                // 更改vue的行政区值
                this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                    key: 'areaCode',
                    value: res,
                });
            }
            // 获取当前行政区对象
            this.parentObject = dataUtil.getAreaObject(this.areaList.at(-1), this.areaTree);
            // 当前的行政区划
            this.$store.dispatch('region/setSelectedRegionObject', this.parentObject);
            this.search();
            this.$emit('closeArea');
        },

        /**
         * 更改时间
         */
        changeParticularYear() {
            // 更改年份选择值
            this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                key: 'year',
                value: this.particularYear,
            });
            this.search();
        },

        /**
         * 更改等级
         */
        changeLevel() {
            this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', { key: 'grade', value: this.levelValue });
            this.search();
        },

        /**
         * 更改品种
         */
        changeVarieties(val) {
            this.varietiesTemp = val;
            // 全部是时候不传种类，就代表查询全部种类
            this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                key: 'varietiesType',
                value: this.varieties === '全部' ? '' : this.varieties,
            });
            // 更改选择
            this.$store.dispatch('tobaccoAnalyse/changeIsAll', this.varieties === '全部' ? true : false);
            this.search();
        },

        /**
         * 更改大类
         */
        changeType() {
            // 植烟环境
            if (this.typeValueValue === '1') {
                this.changeOneLevel(this.environmentType);
            }
            // 烟叶质量
            if (this.typeValueValue === '2') {
                // 获取等级
                this.levelList = [];
                this.dict.forEach((item) => {
                    if (item.type === this.levelType) {
                        this.levelList.push(item);
                    }
                });
                // 默认选中第一个
                this.levelObject = this.levelList.at(0);
                this.levelValue = this.levelObject.value;
                this.changeOneLevel(this.qualityType);
            }
        },
        /**
         * 更改一级目录
         * val 大类类型
         */
        changeOneLevel(val) {
            // 首次初始化
            if (val !== 'no') {
                this.oneLevel = [];
                // 获取一级目录
                this.dict.forEach((item) => {
                    if (item.levelFlag === 1 && item.type === val) {
                        this.oneLevel.push(item);
                    }
                });
                // 一级目录默认选中第一个
                if (this.oneLevel.length > 0) {
                    this.oneLevelObject = this.oneLevel.at(0);
                    this.oneLevelValue = this.oneLevelObject.value;
                }
            } else {
                this.oneLevel.forEach((item) => {
                    if (item.value === this.oneLevelValue) {
                        this.oneLevelObject = item;
                    }
                });
            }
            if (this.typeValueValue === '2') {
                // 更改类型
                this.$store.dispatch('tobaccoAnalyse/changeType', ['2', this.oneLevelValue]);
                // 更改等级
                this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', { key: 'grade', value: this.levelValue });
                // 默认选中第二个
                this.varieties = this.typeList.at(1);
            }
            // 其余的用来变更二级目录
            this.changeTowLevel(this.oneLevelObject);
        },

        /**
         * 更改二目录
         * val 一级选中的对象
         */
        changeTowLevel(val) {
            // 植烟环境
            if (this.typeValueValue === '1') {
                // 是否是首次
                if (val !== 'no') {
                    // 需要初始化信息
                    this.towLevel = [];
                    // 获取二级目录
                    this.dict.forEach((item) => {
                        if (item.parentId === val.id) {
                            this.towLevel.push(item);
                        }
                    });
                    // 如果是土壤类型需要增加”全部“选项
                    if (val.value === '2') {
                        this.towLevel.unshift({ name: '全部', value: '全部' });
                    }
                    // 二级目录默认选中第二个
                    if (this.towLevel.length > 0) {
                        this.towLevelObject = this.towLevel.at(1);
                        this.towLevelValue = this.towLevelObject.value;
                    }
                } else {
                    // 不是首次，需要获取当前选中对象信息
                    this.towLevel.forEach((item) => {
                        if (item.value === this.towLevelValue) {
                            this.towLevelObject = item;
                        }
                    });
                }
                // 更改类型
                if (this.oneLevelValue === '1') {
                    // 开始更改数据类型
                    this.$store.dispatch('tobaccoAnalyse/changeType', ['1', (this.towLevelValue - 1).toString()]);
                }
                if (this.oneLevelValue === '2') {
                    this.$store.dispatch('tobaccoAnalyse/changeType', ['1', '5']);
                    // 更改土壤类型
                    this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                        key: 'soilType',
                        value: this.towLevelValue === '全部' ? '' : this.towLevelObject.name,
                    });
                    // 更改选择
                    this.$store.dispatch('tobaccoAnalyse/changeIsAll', this.towLevelValue === '全部' ? true : false);
                }
            }
            this.$emit('handlerChangeType', val);
            // 查询数据
            this.search();
        },

        /**
         * 获取品种
         */
        async getTypes() {
            if (this.typeValueValue === '2') {
                // 获取查询条件
                let params = this.$store.state['tobaccoAnalyse'].queryParams;
                // 查询种类列表
                await this.$store.dispatch('tobaccoQuality/getTypes', params);
                this.typeList.unshift('全部');
                // 默认选中第二个
                this.varieties = this.typeList.at(1);
                // 全部是时候不传种类，就代表查询全部种类
                await this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                    key: 'varietiesType',
                    value: this.varieties === '全部' ? '' : this.varieties,
                });
                await this.$store.dispatch('tobaccoAnalyse/changeIsAll', this.varieties === '全部' ? true : false);
            }
        },

        /**
         * 查询数据
         * @returns {Promise<void>}
         */
        async search() {
            this.showLoading(null, '#eeeeee2e');
            let temp = '';
            if (this.typeValueValue === '1') {
                temp = this.oneLevelObject.name + '-' + this.towLevelObject.name;
            }
            if (this.typeValueValue === '2') {
                if (this.varietiesTemp !== 'no') {
                    await this.getTypes();
                }
                this.varietiesTemp = 'yes';
                let levelName = '';
                if (this.levelValue === 'B2F') {
                    levelName = '上部';
                }
                if (this.levelValue === 'C3F') {
                    levelName = '中部';
                }
                if (this.levelValue === 'X2F') {
                    levelName = '下部';
                }
                temp = this.oneLevelObject.name + '-' + levelName + '-' + this.varieties;
            }
            await this.$store.dispatch('tobaccoAnalyse/changeTargetCheck', temp);
            // 查询数据
            await this.$store.dispatch('tobaccoAnalyse/search');
            await this.$store.dispatch('tobaccoAnalyse/search1');
            this.hideLoading();
        },
    },
};
