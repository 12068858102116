//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Map3d from './map-3d';
import meteorologicalEcharts from '@/views/business/search-analysis/meteorological-echarts';
import qualityAreaEcharts from '@/views/business/search-analysis/quality-area-echarts';
import qualityYearEcharts from '@/views/business/search-analysis/quality-year-echarts';
import soilConventionalNutrientsEcharts from '@/views/business/search-analysis/soil-conventional-nutrients-echarts';
import meteorologicalYearEcharts from '@/views/business/search-analysis/meteorological-year-echarts';
import soilAllEcharts from '@/views/business/search-analysis/soil-all-echarts';
import qualityAllEcharts from '@/views/business/search-analysis/quality-all-echarts';
import fullScreenEcharts from '@/views/business/search-analysis/full-screen-echarts';
import { tableHeaderLaberObject } from '@/utils/table-header-util';
import ControlArea from '../../../components/tobacco/control-area';

export default {
    name: 'searchAnalysis',
    components: {
        ControlArea,
        Map3d,
        meteorologicalEcharts,
        qualityAreaEcharts,
        qualityYearEcharts,
        soilConventionalNutrientsEcharts,
        meteorologicalYearEcharts,
        soilAllEcharts,
        qualityAllEcharts,
        fullScreenEcharts,
    },
    inject: ['showLoading', 'hideLoading'],
    computed: {
        selectedRegionObject() {
            return this.$store.state['region'].selectedRegionObject;
        },
        type() {
            return this.$store.state['tobaccoAnalyse'].type;
        },
        isAll() {
            return this.$store.state['tobaccoAnalyse'].isAll;
        },
        selectDetail() {
            return this.$store.state['tobaccoAnalyse'].selectDetail;
        },
        randomRGBColor() {
            return this.$store.state['tobaccoAnalyse'].randomRGBColor;
        },
    },
    data() {
        return {
            defaultYear: 2011,
            drawer: false,
            dialogVisible: false,
            dialogArea: false,
            isShow: false,
            active: 2,
        };
    },
    watch: {
        selectedRegionObject: {
            handler() {
                this.handlerRegionChange();
            },
            deep: true,
        },
    },
    methods: {
        closeArea() {
            this.dialogArea = false;
        },
        checkArea() {
            this.dialogArea = true;
            this.viewAll();
        },
        openLink() {
            window.open('http://aos.airag.cn/cn/login');
        },
        // 返回云南省全局
        viewAll() {
            this.$refs.map3d.viewAll();
            this.$store.dispatch('areaManagement/getAreaByCode', this.selectedRegionObject.code).then((data) => {
                this.$store.dispatch('tobaccoAnalyse/changeSelectDetail', {
                    type: 'polygon',
                    data: data,
                });
            });
        },

        handlerChangeType() {
            this.viewAll();
            this.setRandomColor();
        },
        setRandomColor() {
            // 样点随机颜色
            let randomColorList = ['#7BFF46', '#FFF446', '#46FFB3', '#46F2FF', '#94DEF9', '#F9DA94'];
            let index = Math.floor(Math.random() * randomColorList.length);
            let color = randomColorList[index];
            this.$store.dispatch('tobaccoAnalyse/setRandomRGBColor', color);
        },
        handlerRegionChange() {
            this.$refs.map3d.loadGeoJson();

            this.$store.dispatch('areaManagement/getAreaByCode', this.selectedRegionObject.code).then((data) => {
                this.$store.dispatch('tobaccoAnalyse/changeSelectDetail', {
                    type: 'polygon',
                    data: data,
                });
            });
        },
        handlerClickItemPointer(item) {
            let data = JSON.parse(JSON.stringify(item));
            let tempObject = {};
            for (const key in tableHeaderLaberObject[this.type]) {
                let value = tableHeaderLaberObject[this.type][key];
                tempObject[value] = data[key];
            }
            this.$store.dispatch('tobaccoAnalyse/changeSelectDetail', {
                type: 'point',
                data: tempObject,
            });
            this.isShow = true;
            this.active = 1;
        },
        /**
         * 修改一些option的属性,然后全屏展示
         * @param option
         * @returns {*}
         */
        fullScreenEcharts(option) {
            this.drawer = true;
            let temp = JSON.parse(JSON.stringify(option));
            temp.toolbox.show = true;
            temp.toolbox.right = '15px';
            temp.title.textStyle.fontSize = 25;
            temp.title.show = true;
            temp.legend.top = '5%';
            temp.grid.top = '12%';
            temp.label.fontSize = '10px';
            temp.title.textStyle.color = '#000000';
            temp.legend.textStyle.color = '#000000';
            temp.label.color = '#000000';
            temp.xAxis.axisLine.lineStyle.color = '#000000';
            temp.xAxis.axisLabel.rotate = 15;
            temp.yAxis.axisLine.lineStyle.color = '#000000';
            // 放出分割线
            temp.yAxis.splitLine.show = true;
            // 取消滚动条
            temp.dataZoom = [];
            this.$nextTick(() => {
                // 放出保存图片工具
                this.$refs.fullEcharts.barOption = temp;
            });
        },
        /**
         * 更改年份
         * @param year
         */
        getChooseYear(year) {
            // 更改年份选择值
            this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
                key: 'year',
                value: year,
            });
            // 查询数据
            this.search();
            this.setRandomColor();
        },

        /**
         * 查询数据
         * @returns {Promise<void>}
         */
        async search() {
            this.showLoading(null, '#eeeeee2e');
            // 获取品种
            await this.getTypes();
            // 查询数据
            await this.$store.dispatch('tobaccoAnalyse/search');
            this.hideLoading();
        },

        /**
         * 获取品种
         */
        getTypes() {
            // 获取查询条件
            let params = this.$store.state['tobaccoAnalyse'].queryParams;
            // 查询种类列表
            this.$store.dispatch('tobaccoQuality/getTypes', params);
        },
    },
    mounted() {
        // 默认为云南省
        this.$store.dispatch('region/setSelectedRegionObject', {
            name: '云南省',
            level: 1,
        });
        // 默认年份
        this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
            key: 'year',
            value: this.defaultYear,
        });
        this.hideLoading();
        this.$nextTick(() => {
            // 加载完毕播放视频
            this.$refs.video.play();
        });
    },
};
