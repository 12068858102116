export const echartsIndex = {
    /**
     * 降雨量
     */
    rain: [
        {
            name: '大田期降雨量',
            value: 'filedRainfall'
        },
        {
            name: '年平均降雨量',
            value: 'annualRainfall'
        }
    ],

    /**
     * 气温
     */
    airTemperature: [
        {
            name: '年平均温度',
            value: 'annualAverageTemperature'
        },
        {
            name: '大田期平均温度',
            value: 'filedAverageTemperature'
        }
    ],
    /**
     *日照时数
     */
    sunshineHours: [
        {
            name: '年日照时数',
            value: 'annualSunshineHours',
        },
        {
            name: '大田期日照时数',
            value: 'filedSunshineHours',
        }
    ],
    /**
     * 土壤常规养分
     */
    soilConventionalNutrients: [
        {
            name: 'pH值',
            value: 'phValue',
        },
        {
            name: '有机质',
            value: 'organicMatter',
        },
        {
            name: '全氮',
            value: 'totalNitrogen',
        },
        {
            name: '全磷',
            value: 'totalPhosphorus',
        },
        {
            name: '全钾',
            value: 'totalPotassium',
        }
    ],
    /**
     * 常规化学成分 21
     */
    conventionalChemical: [
        {
            name: '总糖',
            value: 'totalSugar',
        },
        {
            name: '钾离子',
            value: 'potassiumIon',
        },
        {
            name: '还原糖',
            value: 'reducingSugar',
        },
        {
            name: '总植物碱',
            value: 'totalAlkaloids',
        },
        {
            name: '氯离子',
            value: 'chlorideIon',
        },
        {
            name: '总氮',
            value: 'totalNitrogen',
        },
        {
            name: '淀粉',
            value: 'starch',
        },
    ],

    /**
     * 外观品质
     */
    appearanceQuality: [
        {
            name: '颜色',
            value: 'color',
        },
        {
            name: '成熟度',
            value: 'maturity',
        },
        {
            name: '叶片结构',
            value: 'bladeStructure',
        },
        {
            name: '身份',
            value: 'identity',
        },
        {
            name: '油份',
            value: 'oil',
        },
    ],

    /**
     * 物理特性
     */
    physical: [
        {
            name: '含梗率',
            value: 'stemPercentage',
        },
        {
            name: '填充值',
            value: 'fillValue',
        },
    ],

    /**
     * 感官评析
     */
    sensory: [
        {
            name: '香气质',
            value: 'aromaValue',
        },
        {
            name: '香气量',
            value: 'aromaQuantity',
        },
        {
            name: '杂气',
            value: 'miscellaneousGas',
        },
        {
            name: '浓度',
            value: 'concentration',
        },
        {
            name: '刺激性',
            value: 'thrill',
        },
        {
            name: '余味',
            value: '',
        },
    ],

    /**
     * 色素多酚
     */
    pigment:[
        {
            name:'类胡萝卜素',
            value:'carotenoids',
        },
        {
            name:'β-胡罗卜素',
            value:'carotene',
        },
        {
            name:'绿原酸',
            value:'chlorogenicAcid',
        },
        {
            name:'莨菪亭',
            value:'hyoscyamine',
        },
        {
            name:'芸香苷',
            value:'rutin',
        },
    ],
}
