//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingForMap from '@/components/loading/LoadingForMap.vue';
import * as Cesium from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import { getGeoServerApiParams } from '@/utils/geoserver-params';
import config from '@/config';

window.CESIUM_BASE_URL = '/static/Cesium/';
Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlZTFiN2RkZi1iNTBmLTRiYWItYWMwYi02ZGZlMzQxZmVlOGYiLCJpZCI6MzE2MDcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1OTU2NDY1NTl9.wtYnBtXVyGpQD3DK9JlUy8tMo-ocpKJeaUF6BiYl-Bs';

export default {
    name: 'Map3d',
    // inject: ['showLoadingForMap', 'hideLoadingForMap'],
    components: {
        LoadingForMap,
    },
    computed: {
        administrativeDivision() {
            return this.$store.state['thematicMap'].administrativeDivision;
        },
        selectedRegionObject() {
            return this.$store.state['region'].selectedRegionObject;
        },
        list() {
            return this.$store.state['tobaccoAnalyse'].list;
        },
        randomRGBColor() {
            return this.$store.state['tobaccoAnalyse'].randomRGBColor;
        },
    },
    data() {
        return {
            viewer: null,
            showFeatures: null,
            loading: false,
            zoomInPointerHeight: 18000, // 缩放的最大高度
            initPoint: Cesium.Cartesian3.fromDegrees(104.3983154296875, 25.31208186007361, 1800000), // 默认相机位置
            pickListDialog: {
                show: false,
                list: [],
            },
            loadingForMapVisible: false,
            loadingForMapMsg: '正在加载地图',
            currentArea: {
                imageryLayers: null,
            },
        };
    },
    watch: {
        list(value) {
            if (value) {
                this.getList();
            }
        },
    },
    methods: {
        initMap: function () {
            this.viewer = new Cesium.Viewer('homeMap', {
                terrainProvider: Cesium.createWorldTerrain(),
                geocoder: false, //右上角 搜索
                homeButton: false, //右上角 Home
                sceneModePicker: false, //右上角 2D/3D切换
                baseLayerPicker: false, //右上角 地形
                navigationHelpButton: false, //右上角 Help
                animation: false, // 左下角 圆盘动画控件
                timeline: false, //时间轴
                shouldAnimate: true,
                fullscreenButton: false, //右下角 全屏控件
                vrButton: false, // 如果设置为true，将创建VRButton小部件。
                scene3DOnly: true, // 每个几何实例仅以3D渲染以节省GPU内存
                infoBox: false, //隐藏点击要素后的提示信息
                selectionIndicator: false,
            });

            this.viewer._cesiumWidget._creditContainer.style.display = 'none'; //隐藏版本信息
            this.viewer.scene.skyBox = new Cesium.SkyBox({
                sources: {
                    positiveX: require('@/assets/images/skyBox/tycho2t3_80_px.jpg'),
                    negativeX: require('@/assets/images/skyBox/tycho2t3_80_mx.jpg'),
                    positiveY: require('@/assets/images/skyBox/tycho2t3_80_py.jpg'),
                    negativeY: require('@/assets/images/skyBox/tycho2t3_80_my.jpg'),
                    positiveZ: require('@/assets/images/skyBox/tycho2t3_80_pz.jpg'),
                    negativeZ: require('@/assets/images/skyBox/tycho2t3_80_mz.jpg'),
                },
            });
            // 最小缩放距离
            this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 800;

            // 地图点击事件
            let handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
            handler.setInputAction(this.mapClick, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            // 监听每次Cesium资源加载，关闭加载动画
            let helper = new Cesium.EventHelper();
            helper.add(this.viewer.scene.globe.tileLoadProgressEvent, (e) => {
                this.loadingForMapVisible = true;
                if (e === 0) {
                    // 关闭加载动画
                    this.loadingForMapVisible = false;
                }
            });
            setTimeout(() => {
                this.tileLoadProgressOver();
            }, 4000);
        },

        viewAll() {
            // 相机飞行到云南省
            this.viewer.camera.flyTo({
                destination: this.initPoint,
                duration: 2,
                // easingFunction: Cesium.EasingFunction.BACK_OUT,
                complete: () => {},
            });
        },

        mapClick: function (movement) {
            let pick = this.viewer.scene.pick(movement.position);
            // let pickList = [];
            if (Cesium.defined(pick)) {
                // 显示选择弹框
                this.list.forEach((item) => {
                    if (pick.id.id === item.pointId) {
                        this.handlerClickItemPointer(item);
                        // pickList.push(JSON.parse(JSON.stringify(item)));
                    }
                });
            }
            // console.log(pickList);
            // if (pickList.length === 1) {
            //     this.handlerClickItemPointer(pickList[0]);
            // }
            // if (pickList.length > 1) {
            //     this.pickListDialog.show = true;
            //     this.pickListDialog.list = pickList;
            // }
        },

        handlerClickItemPointer: function (item) {
            this.pickListDialog.show = false;
            this.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(
                    Number(item.longitude),
                    Number(item.latitude),
                    this.zoomInPointerHeight
                ),
            });
            this.$emit('handlerClickItemPointer', item);
        },

        tileLoadProgressOver() {
            // 相机飞行到云南省
            this.viewer.camera.flyTo({
                destination: this.initPoint,
                duration: 4,
                // easingFunction: Cesium.EasingFunction.BACK_OUT,
                complete: () => {
                    // 加载云南省行政区划（使用wms的服务显示，加载效率快）
                    this.loadYunnanProvinceBorde();
                },
            });
        },
        loadYunnanProvinceBorde() {
            // 云南省边界layers
            // let layers = '8ecd9a90fa316e74538d87122bb8bc2a';
            if (this.viewer.imageryLayers.contains(this.currentArea.imageryLayers)) {
                this.viewer.imageryLayers.remove(this.currentArea.imageryLayers);
                this.currentArea.imageryLayers = null;
            }
            let layers = this.administrativeDivision['2'].layers;
            let urlParams = getGeoServerApiParams(layers, 'wms');
            // 添加云南省行政区划的数据
            this.currentArea.imageryLayers = this.viewer.imageryLayers.addImageryProvider(
                new Cesium.WebMapServiceImageryProvider({
                    url: config.qyGeoApi + '?' + urlParams,
                    layers: layers,
                    parameters: {
                        STYLES: '420ef953d27f4c4da1f95836d86870f6',
                        transparent: true,
                        format: 'image/png',
                    },
                })
            );
        },
        formatGeoJson: async function () {
            /*// 根据行政区划分级来请求不同的layers
            let level = this.selectedRegionObject.level;
            let layers = this.administrativeDivision[level].layers;
            let urlParams = getGeoServerApiParams(layers, 'wfs');
            let url = `${config.qyGeoApi}?${urlParams}&cql_filter=code='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;*/
            // 根据行政区划分级来请求不同的layers
            let url;
            let layers;
            let urlParams;
            let level = this.selectedRegionObject.level;
            switch (level) {
                // 需要获取下级的行政区划
                case 2:
                    layers = this.administrativeDivision[3].layers;
                    urlParams = getGeoServerApiParams(layers, 'wfs');
                    url = `${config.qyGeoApi}?${urlParams}&cql_filter=City_code='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
                    break;
                case 3:
                    layers = this.administrativeDivision[4].layers;
                    urlParams = getGeoServerApiParams(layers, 'wfs');
                    url = `${config.qyGeoApi}?${urlParams}&cql_filter=County_cod='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
                    break;
                default:
                    layers = this.administrativeDivision[level].layers;
                    urlParams = getGeoServerApiParams(layers, 'wfs');
                    url = `${config.qyGeoApi}?${urlParams}&cql_filter=code='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
            }
            return await fetch(url).then((resp) => {
                return resp.json();
            });
        },
        loadGeoJson() {
            // 清除原有的dataSources
            if (this.showFeatures) {
                this.viewer.dataSources.remove(this.showFeatures);
                this.showFeatures = null;
            }
            let checkFault =
                !(this.selectedRegionObject && this.selectedRegionObject.code) || this.selectedRegionObject.level === 1;
            // 下钻到云南省的下级行政区划，隐藏全省的行政区划
            if (this.currentArea.imageryLayers) {
                this.currentArea.imageryLayers.show = this.selectedRegionObject.level === 1;
            }
            if (checkFault) {
                // 相机飞行到云南省
                this.viewer.camera.flyTo({
                    destination: this.initPoint,
                    duration: 2,
                    complete: () => {},
                });
                return;
            }

            let geoJson = this.formatGeoJson();
            this.viewer.dataSources.add(Cesium.GeoJsonDataSource.load(geoJson)).then((dataSource) => {
                this.showFeatures = dataSource;
                let entities = dataSource.entities.values;
                for (let o = 0; o < entities.length; o++) {
                    let entity = entities[o];
                    entity.polygon.outlineWidth = 2.2;
                    entity.polygon.fill = false;
                    entity.polygon.outline = false;
                    entity.polygon.outlineColor = Cesium.Color.fromCssColorString('#20def6');
                    entity.polyline = {
                        positions: entity.polygon.hierarchy._value.positions,
                        width: entity.polygon.outlineWidth,
                        material: Cesium.Color.fromCssColorString('#20def6'),
                    };
                }
                this.viewer.flyTo(dataSource, {
                    offset: new Cesium.HeadingPitchRange(0, -2, 0),
                });
            });
        },

        // 获取查询出来的数据
        getList() {
            // let [r, g, b] = this.randomRGBColor;
            this.viewer.entities.removeAll();
            let imageBlue = require('@/assets/images/contrast/tobacco.png');
            this.list.forEach((item) => {
                let point = this.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Number(item.longitude), Number(item.latitude)),
                    billboard: {
                        image: imageBlue,
                        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                        scale: 1.2,
                        // color: Cesium.Color.fromBytes(r, g, b, 200),
                        color: Cesium.Color.fromCssColorString(this.randomRGBColor),
                    },
                });
                item.pointId = point.id;
            });
        },
    },
    mounted() {
        this.$store.dispatch('thematicMap/setAdministrativeDivision');
        this.initMap();
    },
};
