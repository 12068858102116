//
//
//
//
//
//
//

export default {
	name: "full-screen-echarts",
	data() {
		return {
			barOption: {},
		}
	}
}
