//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {echartsIndex} from "../../../components/tobacco/echarts-index";
import BigNumber from "bignumber.js";

export default {
	name: "meteorological-echarts",
	computed: {
		list() {
			return this.$store.state['tobaccoAnalyse'].list;
		},
		selectedRegionObject() {
			return this.$store.state['region'].selectedRegionObject;
		},
		colorList() {
			return this.$store.state['tobaccoAnalyse'].colorList;
		},
		type() {
			return this.$store.state['tobaccoAnalyse'].type;
		},
		params() {
			return this.$store.state['tobaccoAnalyse'].queryParams;
		},
		targetCheck() {
			return this.$store.state['tobaccoAnalyse'].targetCheck;
		}
	},
	watch: {
		list(value) {
			if (value) {
				this.getChart();
			}
		}
	},
	data() {
		return {
			headerTitle: '',
			indexList: [],
			indexCh: [],
			areaList: [],
			barSource: [
				// ['品种', 'KRK26', '云烟87', '云烟97', '红大', '云烟85', 'NC297', 'NC102', 'K326'],
				// ['玉溪市', 0, 0, 0, 0, 0, 3, 0, 32],
				// ['昆明市', 0, 142, 5, 290, 24, 72, 68, 143],
				// ['临沧市', 36, 198, 6, 0, 0, 0, 0, 106],
				// ['曲靖市', 0, 139, 131, 116, 0, 0, 0, 49],
				// ['楚雄彝族自治州', 2, 67, 0, 23, 0, 3, 0, 201],
				// ['红河哈尼族彝族自治州', 0, 129, 63, 115, 0, 14, 0, 230],
				// ['保山市', 0, 33, 0, 0, 35, 0, 0, 63],
				// ['文山壮族苗族自治州', 0, 64, 10, 0, 0, 0, 0, 9],
				// ['大理白族自治州', 0, 0, 0, 24, 0, 0, 0, 0],
				// ['普洱市', 0, 51, 31, 0, 6, 0, 0, 12]
			],
			barOption: {
				title: {
					text: '',
					left: 'center',
					textStyle: {//标题颜色
						color: "#fff",
					}
				},
				legend: {
					pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff',
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 10,
                    },
					type: 'scroll',//控制图例显示在一行
					orient: 'horizontal'//控制图例显示在一行
				},
				toolbox: {
					show: false,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				grid: {
					top: '18%',
					right: '10%',
				},
				label: {
					show: true,
					color: '#fff',
					position: 'top',
					fontSize: "8px",
				},
				tooltip: {},
				dataset: {
					source: []
				},
				xAxis: {
					type: 'category',
					name: '产地',
					axisLine: {//这是y轴文字颜色
						lineStyle: {
							color: "#fff",
						}
					},
					axisLabel: {
						rotate: 0
					}
				},
				yAxis: {
					type: "value",
					name: "指标平均值", //y坐标轴的名字
					axisLine: {
						lineStyle: {
							color: "#fff",
						}
					},
					axisTick: {show: true},
					splitLine: {show: false},
				},
				dataZoom: [
					{
						xAxisIndex: 0, //这里是从X轴的0刻度开始
						show: true, //是否显示滑动条，不影响使用
						type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
						start: 0, // 从头开始。
						end: 50, // 一次性展示6个。
						height: 20,  //X轴滚动条宽度
						textStyle: {
							color: '#fff'
						}
					},
					{
						type: 'inside',
						start: 94,
						end: 100,
					},
				],
				series: []
			},
		}
	},
	methods: {
		getChart() {
			if (!this.list || this.list.length === 0) {
				this.barSource.series = [];
				this.barOption.dataset.source = [];
				this.barOption.title.text = "暂无数据";
				return;
			}
			this.getArr();
			this.getValue();
			let titleText = this.params.year + '年' + this.selectedRegionObject.name + '-'
				+ this.targetCheck + '统计图';
			this.barOption.title.text = titleText;
			this.barOption.title.show = false;
			this.headerTitle = titleText;
			// this.barOption.yAxis.name = this.targetCheck;
			this.barOption.series = this.getBarSeries(this.indexCh);
			this.barOption.dataset.source = this.barSource;
			// this.$refs.bar.chart.on('click', 'series', () => {
			// 	this.$emit("openMeteorologicalEcharts", this.barOption);
			// })
		},

		handlerFullScreen() {
			this.$emit("openMeteorologicalEcharts", this.barOption);
		},

		/**
		 * 获取指标与行政区列表
		 * @param lists
		 * @returns {*[]}
		 */
		getArr() {
			this.areaList = [];
			this.indexList = [];
			this.indexCh = [];
			// 获取降雨量指标列表
			// console.log(this.type)
			if (this.type === '2') {
				echartsIndex.rain.forEach(item => {
					this.indexCh.push(item.name);
					this.indexList.push(item.value);
				})
			}
			// 获取气温指标列表
			if (this.type === '3') {
				echartsIndex.airTemperature.forEach(item => {
					this.indexList.push(item.value);
					this.indexCh.push(item.name);
				})
			}
			//获取日照时数指标列表
			if (this.type === '4') {
				echartsIndex.sunshineHours.forEach(item => {
					this.indexCh.push(item.name);
					this.indexList.push(item.value);
				})
			}
			let level = this.selectedRegionObject.level;
			// 获取数据的行政区
			this.list.forEach(item => {
				if (level === 1) {
					if (!this.areaList.includes(item['stateCity'])) {
						this.areaList.push(item['stateCity']);
					}
				}
				if (level === 2) {
					if (!this.areaList.includes(item['distinctCounty'])) {
						this.areaList.push(item['distinctCounty']);
					}
				}
				if (level === 3) {
					if (!this.areaList.includes(item['townShip'])) {
						this.areaList.push(item['townShip']);
					}
				}
				if (level === 4) {
					if (!this.areaList.includes(item['townShip'])) {
						this.areaList.push(item['townShip']);
					}
				}
			})
		},

		/**
		 * 获取数据
		 * @param value
		 */
		getValue() {
			this.barSource = [];
			let source = [];
			let breed = ['产地'];
			this.indexCh.forEach(item => {
				breed.push(item);
			})
			source.push(breed);
			this.areaList.forEach(a => {
				let temp = [a];
				this.indexList.forEach(b => {
					let count = new BigNumber("0");
					let sum = new BigNumber("0");
					this.list.forEach(c => {
						if (a && c) {
							let flagA = false;
							let flagB = false;
							let flagC = false;
							let flagD = false;
							if (c['province']) {
								flagA = c['province'].indexOf(a) != -1 || a.indexOf(c['province']) != -1;
							}
							if (c['stateCity']) {
								flagB = c['stateCity'].indexOf(a) != -1 || a.indexOf(c['stateCity']) != -1;
							}
							if (c['distinctCounty']) {
								flagC = c['distinctCounty'].indexOf(a) != -1 || a.indexOf(c['distinctCounty']) != -1;
							}
							if (c['townShip']) {
								flagD = c['townShip'].indexOf(a) != -1 || a.indexOf(c['townShip']) != -1;
							}
							if ((flagA || flagB || flagC || flagD)) {
								let value = new BigNumber(c[b]);
								if (value > 0) {
									sum = sum.plus(value);
									count = count.plus(new BigNumber("1"));
								}
							}
						}
					})

					temp.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
				})
				source.push(temp);
			})
			this.barSource = source;
		},

		/**
		 * 拼接柱状图的series
		 * @param names
		 * @returns {*[]}
		 */
		getBarSeries(names) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						type: 'bar',
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						}
					})
				}
			}
			return series;
		}
	}
}
